// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// console.log("in the packs JS file");

var jQuery = require("jquery")
// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
// require('bootstrap/dist/js/bootstrap.bundle');

// require('sb-admin-2.min');

// require("@rails/ujs").start()

export const rails_ujs = require("@rails/ujs")
rails_ujs.start()
global.Rails = Rails;

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("select2/dist/js/select2")
require("select2")

import "bootstrap"
import "../stylesheets/application.scss"

document.addEventListener("turbolinks:load", ()=>{
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $('#issue_tag_list').select2({
        theme: "bootstrap4",
        tags: true
    });
})




// console.log(select2)

// console.log("finished most of the pack JS file")




// import { library, dom } from '~@fortawesome/fontawesome-svg-core'
// import { fas } from '~@fortawesome/free-solid-svg-icons'
// import { faTwitter } from '~@fortawesome/free-brands-svg-icons'
// library.add(fas, faTwitter)
// Kicks off the process of finding <i> tags and replacing with <svg>
// dom.watch()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
// require("chosen-js")

// $('#issue_tag_list').chosen({width: "95%"});

// $('#issue_tag_list').select2({
//     theme: "bootstrap4",
//     tags: true
// });
// $(document).on('ready page:load', function () {
//     console.log($('#issue_tag_list'))
// }